/* eslint-disable */
interface IProps {
	className?: string;
}

const Footer = ({ className = '' }: IProps) => {
	return (
		<div className={`basis-50 pt-14 pb-5 ${className}`}>
			<ul className="text-center text-[12px] text-gray-500">
				<li>
					Sistema BP Online RH (Área do Administrador) - Desenvolvido por{' '}
					<a
						href="https://www.bemprotege.com.br"
						className="text-blue-400 hover:underline"
						target="_blank"
					>
						Bem Protege
					</a>
				</li>
			</ul>
		</div>
	);
};

export default Footer;
