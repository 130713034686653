/* eslint-disable */
/* eslint-disable react/jsx-one-expression-per-line */
import { Link, useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';

import { Helmet } from 'react-helmet';

import { getHasLocalStorageAuth } from '../helpers/handleStorage';
import Placeholder from '../components/Placeholder';
import HeaderTop from '../components/HeaderTop/HeaderTop';
import HeaderTopSecondary from '../components/HeaderTopSecondary/HeaderTopSecondary';

import PaginationJson from '../components/PaginationJson';

import { END_POINT_BASE } from '../Api/Api';

// import { getPayments } from '../Api/paymentsClientHandle';
import { getClientData, getClientCSV } from '../Api/clientsClientHandle';
import Footer from '../components/Footer/Footer';

import 'bootstrap-icons/font/bootstrap-icons.css';

const PageClientHub = () => {
	const history = useHistory();

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [dataLeads, setDataLeads] = useState<any[] | null>(null);
	const [dataClient, setDataClient] = useState<any>(null);
	const [fileToken, setFileToken] = useState<any>();

	//
	const [hasAllQueries, setHasAllQueries] = useState(true);
	const [messages, setMessages] = useState<any>(null);
	const [updateSearch, setUpdateSearch] = useState(new Date().getTime()); // last update component
	const [lastTimestampResult, setLastTimestampResult] = useState(''); // last update component
	const [hasSomeFilter, setHasSomeFilter] = useState<boolean>(false);

	// filter
	const [currentPage, setCurrentPage] = useState(1);
	const [currentOrder, setCurrentOrder] = useState(1);
	const [currentFilter, setCurrentFilter] = useState<{
		state: string | null;
		name: string | null;
		email: string | null;
		whatsapp: string | null;
		id: string | null;
	}>({
		state: '',
		name: '',
		email: '',
		whatsapp: '',
		id: '',
	});

	useEffect(() => {
		const query = new URLSearchParams(history.location.search);
		let paramCurrentPage = Number(query.get('page'));
		let paramCurrentOrder = Number(query.get('order'));
		let paramCurrentFilterState = query.get('state');
		let paramCurrentFilterName = query.get('name');
		let paramCurrentFilterEmail = query.get('email');
		let paramCurrentFilterId = query.get('id');

		if (
			(paramCurrentFilterEmail !== null && paramCurrentFilterEmail !== '') ||
			(paramCurrentFilterName !== null && paramCurrentFilterName !== '') ||
			(paramCurrentFilterId !== null && paramCurrentFilterId !== '') ||
			(paramCurrentFilterState !== null && paramCurrentFilterState !== '')
		) {
			setHasSomeFilter(true);
			console.log('paramCurrentFilterEmail', paramCurrentFilterEmail);
		} else {
			setHasSomeFilter(false);
			console.log('nada', paramCurrentFilterEmail);
		}

		if (paramCurrentPage) {
			console.log('paxxge');
		} else {
			console.log('nothing');
			history.push(`/dashboard?page=1&order=1`);
		}
		setCurrentPage(paramCurrentPage);
		setCurrentOrder(paramCurrentOrder);
		setCurrentFilter({
			...currentFilter,
			state: paramCurrentFilterState ? paramCurrentFilterState : '',
			name: paramCurrentFilterName ? paramCurrentFilterName : '',
			email: paramCurrentFilterEmail ? paramCurrentFilterEmail : '',
			id: paramCurrentFilterId ? paramCurrentFilterId : '',
		});
		setUpdateSearch(new Date().getTime());

		const d = new Date();
		const dformat =
			[d.getMonth() + 1, d.getDate(), d.getFullYear()].join('/') +
			' ' +
			[d.getHours(), d.getMinutes(), d.getSeconds()].join(':');

		setLastTimestampResult(`${dformat}`);

		// document.write();
	}, [history.location.search]);

	const [formFieldsSearch, setFormFieldsSearch] = useState<any[]>([
		{
			name: 'page',
			value: currentPage,
			error: '',
			type: 'text',
		},
		{
			name: 'order',
			value: currentOrder,
			error: '',
			type: 'text',
		},
	]);

	const updatePagination = (page: number) => {
		console.log('update page', page);
		history.push(
			`/dashboard?page=${page}&order=${currentOrder}&state=${currentFilter.state}&name=${currentFilter.name}&email=${currentFilter.email}&id=${currentFilter.id}`,
		);
	};

	const loadingDataClient = async () => {
		await getClientData().then((responseData: any) => {
			if (responseData.data.status === 1) {
				setDataClient(responseData.data.client);
			} else {
			}
		});
	};

	//
	const handleOrder = (e: any) => {
		setCurrentOrder(e.target.value);
		console.log('filter alter', e.target.value, formFieldsSearch);
		history.push(
			`/dashboard?page=${currentPage}&order=${e.target.value}&state=${currentFilter.state}&name=${currentFilter.name}&email=${currentFilter.email}&id=${currentFilter.id}`,
		);
	};

	//
	const handleDownloadCSV = async (e: any) => {
		e.preventDefault();
		// window.location.href = 'http://localhost';
		// history.push('http://localhost');

		await getClientCSV(
			`page=1&order=${currentOrder}&state=${currentFilter.state}&name=${currentFilter.name}&email=${currentFilter.email}&id=${currentFilter.id}`,
		).then((responseData: any) => {
			if (responseData.data.status === 1) {
				console.log('data csv', responseData);
				// 		// setDataClient(responseData.data.client);
			} else {
				console.log('erro csv', responseData);
			}
		});

		// handleFilter(e);
		console.log('kkk');
	};

	//
	const handleFilter = (e: any) => {
		e.preventDefault();
		history.push(
			`/dashboard?page=1&order=${currentOrder}&state=${currentFilter.state}&name=${currentFilter.name}&email=${currentFilter.email}&id=${currentFilter.id}`,
		);

		console.log('console');
	};

	//
	const handleResetFilters = () => {
		history.push(`/dashboard?page=1&order=1`);
	};

	useEffect(() => {
		// console.log('url', history.location.search);

		const localStorageAuth = getHasLocalStorageAuth();
		setFileToken({
			token: localStorageAuth.data.token,
			email: localStorageAuth.data.email,
			timestamp: localStorageAuth.data.timestamp,
		});
		loadingDataClient();
	}, []);

	if (dataClient === null) {
		return <Placeholder />;
	}

	return (
		<>
			<Helmet>
				<title>Dashboard - Área do RH</title>
				<meta name="description" content="Visualize os currículos do RH" />
			</Helmet>

			<div className="min-h-screen flex justify-between flex-col">
				<div>
					<HeaderTop />

					<div className="bg-gray-200 py-2 px-5 w-full flex justify-between ">
						<ul className="flex space-x-3 items-center">
							<li className="text-sm ">
								<p className=" inline-block  text-gray-400">Dashboard</p>
							</li>
						</ul>
						<div>
							<span className="text-sm  text-gray-500 mr-3">
								Atualizado: {lastTimestampResult}h
							</span>
							<button
								type="button"
								onClick={(e: any) => handleDownloadCSV(e)}
								className="text-blue-500 hover:underline text-sm "
							>
								Baixar .CSV
							</button>
						</div>
					</div>

					<div className="bg-gray-100 py-1">
						<div className="xl:container mx-auto my-2 md:my-3 px-2 md:px-5 3xl:px-0 ">
							<h3 className="font-bold">Opções de busca</h3>
							<form
								className="container mx-auto md:flex justify-between items-center md:space-x-3"
								onSubmit={handleFilter}
							>
								<div className="flex flex-col w-full">
									<label htmlFor="filter_state">Estado:</label>
									<select
										name=""
										id="filter_state"
										onChange={(e) => setCurrentFilter({ ...currentFilter, state: e.target.value })}
										value={String(currentFilter.state)}
										className={`border px-2 py-1 rounded-md ${
											String(currentFilter.state).length > 0
												? 'bg-white border-green-500'
												: 'bg-transparent'
										} `}
									>
										<option value="">Todos</option>
										<option value="Acre">Acre</option>
										<option value="Alagoas">Alagoas</option>
										<option value="Amapá">Amapá</option>
										<option value="Amazonas">Amazonas</option>
										<option value="Bahia">Bahia</option>
										<option value="Ceará">Ceará</option>
										<option value="Distrito Federal">Distrito Federal</option>
										<option value="Espírito Santo">Espírito Santo</option>
										<option value="Goiás">Goiás</option>
										<option value="Maranhão">Maranhão</option>
										<option value="Mato Grosso">Mato Grosso</option>
										<option value="Mato Grosso do Sul">Mato Grosso do Sul</option>
										<option value="Minas Gerais">Minas Gerais</option>
										<option value="Pará">Pará</option>
										<option value="Paraíba">Paraíba</option>
										<option value="Paraná">Paraná</option>
										<option value="Pernambuco">Pernambuco</option>
										<option value="Piauí">Piauí</option>
										<option value="Rio de Janeiro">Rio de Janeiro</option>
										<option value="Rio Grande do Norte">Rio Grande do Norte</option>
										<option value="Rio Grande do Sul">Rio Grande do Sul</option>
										<option value="Rondônia">Rondônia</option>
										<option value="Roraima">Roraima</option>
										<option value="Santa Catarina">Santa Catarina</option>
										<option value="São Paulo">São Paulo</option>
										<option value="Sergipe">Sergipe</option>
										<option value="Tocantins">Tocantins</option>
									</select>
								</div>
								<div className="flex flex-col w-full">
									<label htmlFor="filter_name">Nome:</label>
									<input
										type="text"
										id="filter_name"
										onChange={(e) => setCurrentFilter({ ...currentFilter, name: e.target.value })}
										value={currentFilter.name ? String(currentFilter.name) : ''}
										className={`border px-2 py-1 rounded-md ${
											String(currentFilter.name).length > 0
												? 'bg-white border-green-500'
												: 'bg-transparent'
										} `}
									/>
								</div>

								<div className="flex flex-col w-full">
									<label htmlFor="filter_email">E-mail:</label>
									<input
										type="text"
										id="filter_email"
										onChange={(e) => setCurrentFilter({ ...currentFilter, email: e.target.value })}
										value={currentFilter.email ? String(currentFilter.email) : ''}
										className={`border px-2 py-1 rounded-md ${
											String(currentFilter.email).length > 0
												? 'bg-white border-green-500'
												: 'bg-transparent'
										} `}
									/>
								</div>

								<div className="flex flex-col w-full">
									<label htmlFor="filter_id">ID:</label>
									<input
										type="text"
										id="filter_id"
										onChange={(e) => setCurrentFilter({ ...currentFilter, id: e.target.value })}
										value={currentFilter.id ? String(currentFilter.id) : ''}
										className={`border px-2 py-1 rounded-md ${
											String(currentFilter.id).length > 0
												? 'bg-white border-green-500'
												: 'bg-transparent'
										} `}
									/>
								</div>

								<div className="flex flex-col w-full">
									<label htmlFor="filter_state">&nbsp;</label>
									<button
										type="submit"
										className="px-3 py-1 w-full rounded-md bg-blue-500 hover:bg-blue-600 text-white shadow"
									>
										Atualizar busca
									</button>
								</div>
							</form>

							{hasSomeFilter && (
								<div className="pt-2">
									<button
										className="text-blue-600 hover:underline"
										onClick={() => handleResetFilters()}
									>
										Resetar busca
									</button>
								</div>
							)}
						</div>
					</div>

					<div className="xl:container mx-auto my-2 md:my-5 px-2 md:px-5 3xl:px-0 ">
						<div className="flex justify-between items-center  mb-1 md:mb-3 my-1 md:my-5">
							<div className="flex justify-between items-center space-x-3">
								<h2 className="font-bold text-md md:text-lg">Últimos cadastros</h2>
							</div>

							<form className="flex justify-end space-x-3 items-center">
								<label htmlFor="order" className="w-full">
									Ordenar por:
								</label>
								<select
									name=""
									id="order"
									onChange={(e) => handleOrder(e)}
									value={currentOrder}
									className="border border-gray-200 w-full h-8"
								>
									<option value="1">Mais recentes</option>
									<option value="2">Mais antigos</option>
									<option value="3">Nome a-z</option>
									<option value="4">Nome z-a</option>
									<option value="5">Email a-z</option>
									<option value="6">Email z-a</option>
								</select>
							</form>
						</div>

						{hasAllQueries && (
							<PaginationJson
								data={`${END_POINT_BASE}/leads/leads`}
								params={`${history.location.search}`}
								setData={setMessages}
								lastUpdate={updateSearch}
								pathData="leads"
								saveLocalJson={false}
								callbackChangePage={updatePagination}
								currentPage={currentPage}
								perPage={20}
							>
								{messages ? (
									messages.length > 0 ? (
										<div className="my-2 md:my-5 overflow-x-auto">
											<table className="table-fixed sm:table-auto border-collapse border border-slate-400 w-full tr-even:bg-grey-100">
												<thead>
													<tr className="bg-gray-400">
														<th className="border border-slate-300 text-sm w-10">Id</th>
														<th className="border border-slate-300 text-sm w-40">Nome</th>
														<th className="border border-slate-300 text-sm w-40">E-mail</th>
														<th className="border border-slate-300 text-sm w-40">Whatsapp</th>
														<th className="border border-slate-300 text-sm w-40">
															Estado / Cidade
														</th>
														<th
															className="border border-slate-300 text-sm w-40"
															title="Qual sua experiência com vendas?:
"
														>
															Experiência
														</th>
														<th
															className="border border-slate-300 text-sm w-40"
															title="Porque você gosta de trabalhar com vendas?:
"
														>
															Porque
														</th>
														<th
															className="border border-slate-300 text-sm w-40"
															title="Você se considera uma pessoa que gosta de desafios e metas?:
"
														>
															Gosto
														</th>
														<th
															className="border border-slate-300 text-sm w-40"
															title="Cite um desafio que superou e como isso te ajudou?:
"
														>
															Desafios
														</th>
														<th className="border border-slate-300 text-sm w-40">Cadastro</th>
														<th className="border border-slate-300 text-sm w-40">Currículo</th>
													</tr>
												</thead>
												<tbody>
													{messages.map((lead: any, i: number) => {
														return (
															<tr className="odd:bg-white even:bg-gray-200" key={lead.id}>
																<td className="border border-slate-300 text-sm py-1 px-2 ">
																	{lead.id}
																</td>
																<td className="border border-slate-300 text-sm py-1 px-2">
																	{lead.name}
																</td>
																<td className="border border-slate-300 text-sm py-1 px-2 ">
																	{lead.email}
																</td>
																<td className="border border-slate-300 text-sm py-1 px-2">
																	{lead.whatsapp}
																</td>
																<td className="border border-slate-300 text-sm py-1 px-2 ">
																	{lead.state} / {lead.city}
																</td>
																<td className="border border-slate-300 text-sm py-1 px-2">
																	<ResumeRow text={lead.experience} />
																</td>
																<td className="border border-slate-300 text-sm py-1 px-2 ">
																	<ResumeRow text={lead.why} />
																</td>
																<td className="border border-slate-300 text-sm py-1 px-2 ">
																	<ResumeRow text={lead.like} />
																</td>
																<td className="border border-slate-300 text-sm py-1 px-2">
																	<ResumeRow text={lead.challenge} />
																</td>
																<td className="border border-slate-300 text-sm py-1 px-2">
																	{lead.timestamp}
																</td>
																<td className="border border-slate-300 text-sm py-1 px-2">
																	<a
																		href={`https://www.bemprotegeonline.com.br/api/upload/${lead.resume}`}
																		target="_blank"
																		className="text-blue-600 hover:underline"
																	>
																		Download
																	</a>
																</td>
															</tr>
														);
													})}
												</tbody>
											</table>
										</div>
									) : (
										<Placeholder title="Nada encontrado :(" />
									)
								) : (
									<Placeholder title="Buscando..." />
								)}
							</PaginationJson>
						)}
					</div>
				</div>

				<Footer />

				{/* <pre className="text-xs">{JSON.stringify(dataClient, null, 1)}</pre> */}
				{/* <pre className="text-xs">{JSON.stringify(dataPayments, null, 1)}</pre> */}
			</div>
		</>
	);
};

export default PageClientHub;

const ResumeRow = ({ text }: { text: string }) => {
	const [fullText, setFullText] = useState(false);
	const resumeTotal = 20;
	const resume = text.length >= 1 ? text.substring(0, resumeTotal) : '';

	const handleReadMore = () => {
		setFullText(true);
	};
	const handleReadLess = () => {
		setFullText(false);
	};

	return (
		<>
			{text.length < resumeTotal || fullText ? text : resume}
			{text.length > resumeTotal && !fullText && '...'}
			{text.length > resumeTotal && !fullText && (
				<button
					type="button"
					onClick={() => handleReadMore()}
					className="text-blue-600 hover:underline "
				>
					Ver mais <i className="bi-chevron-down text-sm" />
				</button>
			)}

			{text.length > resumeTotal && fullText && (
				<button
					type="button"
					onClick={() => handleReadLess()}
					className="text-blue-600 hover:underline "
				>
					Ver menos <i className="bi-chevron-up text-sm" />
				</button>
			)}
		</>
	);
};
