/* eslint-disable */

import axios from 'axios';
import { getHasLocalStorageAuth } from '../helpers/handleStorage';

import { END_POINT_BASE } from './Api';

// GET DATA
export const getClientData = async () => {
	const localStorageAuth = getHasLocalStorageAuth();
	const hasLocalStorageAuth = localStorageAuth.status;
	let serverResponse = { data: { status: 0 } };

	if (hasLocalStorageAuth) {
		const GET_PARAMS = `?auth_email=${localStorageAuth.data.email}&auth_timestamp=${localStorageAuth.data.timestamp}&role=${localStorageAuth.data.role}`;

		const END_POINT = END_POINT_BASE + '/clients/data-client-user' + GET_PARAMS;
		await axios({
			method: 'get',
			url: END_POINT,
			headers: { Authorization: `Bearer ${localStorageAuth.data.token}` },
		})
			.then((response) => {
				// 1 - done
				if (response.data.status === 1) {
					serverResponse = {
						data: {
							status: response.data.status,
							client: response.data.client,
							message: response.data.message,
						},
					};
				} else {
					serverResponse = {
						data: {
							status: response.data.status,
							message: response.data.message,
						},
					};
				}
			})
			.catch((error) => {});
	}

	return serverResponse;
};

// GET DATA
export const getClientCSV = async (param) => {
	const localStorageAuth = getHasLocalStorageAuth();
	const hasLocalStorageAuth = localStorageAuth.status;
	let serverResponse = { data: { status: 0 } };

	if (hasLocalStorageAuth) {
		const GET_PARAMS = `?auth_email=${localStorageAuth.data.email}&auth_timestamp=${localStorageAuth.data.timestamp}&role=${localStorageAuth.data.role}`;

		let END_POINT =
			END_POINT_BASE +
			'/leads/get_client_leads_csv.php' +
			GET_PARAMS +
			'&' +
			param +
			'&token=' +
			localStorageAuth.data.token;
		END_POINT = END_POINT.replace(/  +/g, '');
		// END_POINT = END_POINT.replace(/  +/g, ' ');
		console.log('take', END_POINT);
		window.location.href = END_POINT;

		serverResponse = {
			data: {
				status: 1,
			},
		};

		// await axios({
		// 	method: 'get',
		// 	url: END_POINT,
		// 	headers: { Authorization: `Bearer ${localStorageAuth.data.token}` },
		// })
		// 	.then((response) => {
		// 		// 1 - done
		// 		if (response.data.status === 1) {
		// 			serverResponse = {
		// 				data: {
		// 					status: response.data.status,
		// 					client: response.data.client,
		// 					message: response.data.message,
		// 				},
		// 			};
		// 		} else {
		// 			serverResponse = {
		// 				data: {
		// 					status: response.data.status,
		// 					message: response.data.message,
		// 				},
		// 			};
		// 		}
		// 	})
		// 	.catch((error) => {});
	}

	return serverResponse;
};
